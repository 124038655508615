import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

// core components

function Carriers() {
  return (
    <>
      <div className="section section-download" id="carrier-section"
      >
        <Container>
          <Row className="justify-content-md-center">
          <Col className="text-center" lg="8" md="12">
              <h3 className="title">Want to work with us?</h3>
            </Col>
            <Col className="text-center" lg="8" md="12">
              <h3 className="title">PHP DEVELOPER</h3>
              <h5 className="description">
                Skills: PHP, MySQL, AJAX, HTML, CSS, JavaScript, jQuery, JSON, XML and GIT.
              </h5>
            </Col>
            <Col className="text-center" lg="8" md="12">
              <Button
                className="btn-round"
                color="info"
                href=""
                outline
                role="button"
                size="lg"
                target="_blank"
              >
                Experience: 2-6 years
                Qualification: BE/BTech/MCA
              </Button>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <br></br>

          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12">
              <h3 className="title">.NET Developer</h3>
              <h5 className="description">
              Skills: C#, ASP.net, SQL Server, Web Services, AJAX, HTML, CSS, JavaScript, jQuery, JSON, XML and GIT.
              </h5>
              <Button
                className="btn-round"
                color="info"
                href=""
                outline
                role="button"
                size="lg"
                target="_blank"
              >
                Experience: 2-6 years
                Qualification: BE/BTech/MCA
              </Button>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <br></br>
          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12">
              <h3 className="title">Test Engineer</h3>
              <h5 className="description">
              Skills: Knowledge in Database and SQL Queries, HTML, XML and GIT.
              </h5>
              <Button
                className="btn-round"
                color="info"
                href=""
                outline
                role="button"
                size="lg"
                target="_blank"
              >
                Experience: 0-2 years
                Qualification: BE/BTech/MCA
              </Button>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12">
              <h3 className="title">Send your resume to</h3>
              <Button
                className="btn-round"
                color="info"
                href=""
                outline
                role="button"
                size="lg"
                target="_blank"
              >
                hr@jmatics.net
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Carriers;
