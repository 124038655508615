/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
// core components

function IndexHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/header1.jpg").default + ")",
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className="content-center brand">
            
            <h1 className="h1-seo">J'OuvertMatics Technologies</h1>
            <h3>We are an IT consulting firm values client satisfaction as an ultimate achievement.Our long-term relationship with our clients proves we are right at it. Moreover, we have an excellent team of engineers who uses the latest technologies to solve and develop outstanding software solutions for our clients.</h3>
          </div>
          <h6 className="category category-absolute">
          </h6>
        </Container>
      </div>
    </>
  );
}

export default IndexHeader;
