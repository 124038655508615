import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function ContactUs() {
  return (
    <>
        <div className="section section-contact-us text-center" id="contact-us">
          <Container>
            <h2 className="title">Contact Us</h2>
            <p className="description">J'OuvertMatics Technologies Pvt. Ltd. <br></br>
                SBC 2202, 2nd Floor, Yamuna, <br></br>
                Phase III Campus, Technopark, <br></br>
                Thiruvananthapuram, Kerala, India <br></br>
                Pin. 695 583</p>
          </Container>
        </div>
        <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
    </>
  );
}

export default ContactUs;
