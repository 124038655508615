import React from "react";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// plugin that creates slider
import Slider from "nouislider";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function BasicElements() {

  return (
    <>
   
      <div className="section section-team text-center" id="our-services">
          <Container>
            <h2 className="title">Services we provide</h2>
            <div className="team">
              <Row>
                <Col md="4">
                  <div className="team-player">
                    <h4 className="title">E-commerce Software Development</h4>
                    <p className="category text-info">E-commerce</p>
                    <i className="now-ui-icons shopping_cart-simple"></i>
                    <p className="description">
                    Expertise in E-commerce platforms Magento, NopCommerce, Joomla Virtuemart, OsCommerce, PrestaShop, AspDotNetStorefront etc.{" "}
                     
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <h4 className="title">Mobile Application Development</h4>
                    <p className="category text-info">Mobile</p>
                    <i className="now-ui-icons tech_mobile"></i>
                    <p className="description">
                    Develops cutting edge Android/iOS/Windows applications and Responsive mobile websites.
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <h4 className="title">Systems Integration and Development</h4>
                    <p className="category text-info">Systems</p>
                    <i className="now-ui-icons tech_laptop"></i>
                    <p className="description">
                    Integration of various services like Payment gateways, Tax Services. Develops Laravel, CodeIgniter framework based applications.
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <h4 className="title">Cloud Application Development</h4>
                    <p className="category text-info">Cloud</p>
                    <i className="now-ui-icons arrows-1_cloud-upload-94"></i>
                    <p className="description">
                    Develops Windows Azure and Amazon Web services based applications. Manages DevOps, CI/CD and various cloud based build systems.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
    </>
  );
}

export default BasicElements;
