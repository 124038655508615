/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function DefaultFooter() {
  return (
    <>
      <footer className="footer footer-default">
      <Container>
        <nav>
          <ul>
            <li>
              <a
                href=""
              >
                J'OUVERTMATICS
              </a>
            </li>
            <li>
              <a
                href="root"
              >
                Home
              </a>
            </li>
          </ul>
        </nav>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()}, J'OUVERTMATICS TECHNOLOGIES PVT LTD
        </div>
      </Container>
      </footer>
    </>
  );
}

export default DefaultFooter;
