import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              href=""
              id="navbar-brand"
            >
             <div className="logo-container-jmatics">
                      <img 
                        alt="..."
                        src={require("assets/img/JMATICS-logo.png").default}
                    
                      ></img>
                    </div>
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              Team Jmatics
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                  href="#home"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("root")
                      .scrollIntoView();
                  }}
                >
                  <i className="now-ui-icons business_globe"></i>
                  <p>Home</p>
                </NavLink>
              </NavItem>
            
              <NavItem>
                <NavLink
                  href="#aboutUs"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("about-section")
                      .scrollIntoView();
                  }}
                >
                  <i className="now-ui-icons business_badge"></i>
                  <p>ABOUT</p>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="#services"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("our-services")
                      .scrollIntoView();
                  }}
                >
                  <i className="now-ui-icons emoticons_satisfied"></i>
                  <p>SERVICES</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("carrier-section")
                      .scrollIntoView();
                  }}
                >
                  <i className="now-ui-icons objects_spaceship"></i>
                  <p>CARRIERS</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#contactus"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("contact-us")
                      .scrollIntoView();
                  }}
                >
                  <i className="now-ui-icons location_pin"></i>
                  <p>CONTACT</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://twitter.com"
                  target="_blank"
                  id="twitter-tooltip"
                >
                  <i className="fab fa-twitter"></i>
                  <p className="d-lg-none d-xl-none">Twitter</p>
                </NavLink>
                <UncontrolledTooltip target="#twitter-tooltip">
                  Follow us on Twitter
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.facebook.com/"
                  target="_blank"
                  id="facebook-tooltip"
                >
                  <i className="fab fa-facebook-square"></i>
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
                <UncontrolledTooltip target="#facebook-tooltip">
                  Like us on Facebook
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.instagram.com"
                  target="_blank"
                  id="instagram-tooltip"
                >
                  <i className="fab fa-instagram"></i>
                  <p className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>
                <UncontrolledTooltip target="#instagram-tooltip">
                  Follow us on Instagram
                </UncontrolledTooltip>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
