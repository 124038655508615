import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function AboutUs() {
  return (
    <>
      <div className="section" id="about-section">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col lg="8" md="12">
              <h2 className="title">About J'OuvertMatics Technologies</h2>
              <h5 className="description">
              We have more than 14 years of experience in the software business with global clients from the United States, Mexico, South Korea, and India. With an enthusiastic and energetic team of professionals with excellent skill sets, we continue to provide successful software solutions to businesses across the world.
              </h5>
            </Col>
          </Row>
        </Container>
        <br></br>
        <br></br>
 
      </div>
    </>
  );
}

export default AboutUs;
